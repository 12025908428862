const routers = [
    {
        path: '/',
        name: "登录页",
        component: () => import("@/components/admin/Login")
    },
    {
        path: '/home-admin',
        component: () => import("@/components/admin/home/Home"),
        name: "主页",
        children: [
            {
                path: '/home-admin/home-page',
                name: "首页",
                component: () => import("@/components/admin/home/HomePage"),
                meta: [
                    { name: '首页', url: '/home-admin/home-page' },
                ]
            },
            {
                path: '/home-admin/routineManage/user-list',
                name: "人员管理",
                component: () => import("@/components/admin/routineManage/UserList"),
                meta: [
                    { name: '常规管理' },
                    { name: '人员管理', url: '/home-admin/routineManage/user-list' },
                ]
            },
            {
                path: '/home-admin/routineManage/role-list',
                name: "角色管理",
                component: () => import("@/components/admin/routineManage/SysRole"),
                meta: [
                    { name: '常规管理' },
                    { name: '角色管理', url: '/home-admin/routineManage/role-list' },
                ]
            },
            {
                path: '/home-admin/routineManage/material-type',
                name: "物料类型",
                component: () => import("@/components/admin/routineManage/MaterialType"),
                meta: [
                    { name: '常规管理' },
                    { name: '物料类型', url: '/home-admin/routineManage/material-type' },
                ]
            },
            {
                path: '/home-admin/routineManage/material-model',
                name: "物料型号",
                component: () => import("@/components/admin/routineManage/MaterialModel"),
                meta: [
                    { name: '常规管理' },
                    { name: '物料型号', url: '/home-admin/routineManage/material-model' },
                ]
            },
            {
                path: '/home-admin/routineManage/region-manage',
                name: "区域管理",
                component: () => import("@/components/admin/routineManage/RegionManage"),
                meta: [
                    { name: '常规管理' },
                    { name: '区域管理', url: '/home-admin/routineManage/region-manage' },
                ]
            },
            {
                path: '/home-admin/routineManage/company-manage',
                name: "公司管理",
                component: () => import("@/components/admin/routineManage/CompanyManager"),
                meta: [
                    { name: '常规管理' },
                    { name: '公司管理', url: '/home-admin/routineManage/company-manage' },
                ]
            },
            {
                path: '/home-admin/routineManage/cable-brand',
                name: "电缆品牌",
                component: () => import("@/components/admin/routineManage/CableBrand"),
                meta: [
                    { name: '常规管理' },
                    { name: '电缆品牌', url: '/home-admin/routineManage/cable-brand' },
                ]
            },
            {
                path: '/home-admin/routineManage/device-brand',
                name: "充电桩品牌",
                component: () => import("@/components/admin/routineManage/DeviceBrand"),
                meta: [
                    { name: '常规管理' },
                    { name: '充电桩品牌', url: '/home-admin/routineManage/device-brand' },
                ]
            },
            {
                path: '/home-admin/routineManage/quickWord-manage',
                name: "快捷语管理",
                component: () => import("@/components/admin/routineManage/QuickWordsManage"),
                meta: [
                    { name: '常规管理' },
                    { name: '快捷语管理', url: '/home-admin/routineManage/quickWord-manage' },
                ]
            },
            {
                path: '/home-admin/routineManage/userEntry-manage',
                name: "入职管理",
                component: () => import("@/components/admin/routineManage/UserEntry"),
                meta: [
                    { name: '常规管理' },
                    { name: '入职管理', url: '/home-admin/routineManage/userEntry-manage' },
                ]
            },
            {
                path: '/home-admin/routineManage/group-manage',
                name: "群组管理",
                component: () => import("@/components/admin/routineManage/GroupManage"),
                meta: [
                    { name: '常规管理' },
                    { name: '群组管理', url: '/home-admin/routineManage/group-manage' },
                ]
            },
            {
                path: '/home-admin/order/settle-manage',
                name: "结算管理",
                component: () => import("@/components/admin/order/SettleManage"),
                meta: [
                    { name: '结算管理', url: '/home-admin/order/settle-manage' },
                ]
            },
            {
                path: '/home-admin/order/order-manage',
                name: "订单管理",
                component: () => import("@/components/admin/order/OrderManage"),
                meta: [
                    { name: '订单管理', url: '/home-admin/order/order-manage' },
                ]
            },
            {
                path: '/home-admin/order/complete-rate-manage',
                name: "报表管理",
                component: () => import("@/components/admin/order/CompleteRateManage"),
                meta: [
                    { name: '报表管理', url: '/home-admin/order/complete-rate-manage' },
                ]
            },
            {
                path: '/home-admin/order/order-detail',
                name: "订单详情",
                component: () => import("@/components/admin/order/OrderDetail"),
                meta: [
                    { name: '订单详情', url: '/home-admin/order/order-detail' },
                ]
            },
            {
                path: '/home-admin/device/device-class',
                name: "设备类别",
                component: () => import("@/components/admin/device/DeviceClass"),
                meta: [
                    { name: '设备管理' },
                    { name: '设备类别', url: '/home-admin/device/device-class' },
                ]
            },
            {
                path: '/home-admin/device/device-image-setting',
                name: "图片模板管理",
                component: () => import("@/components/admin/device/DeviceImageSetting"),
                meta: [
                    { name: '设备管理' },
                    { name: '图片模板管理', url: '/home-admin/device/device-image-setting' },
                ]
            },
            {
                path: '/home-admin/device/device-manage',
                name: "设备信息",
                component: () => import("@/components/admin/device/DeviceManage"),
                meta: [
                    { name: '设备管理' },
                    { name: '设备信息', url: '/home-admin/device/device-manage' },
                ]
            },
            {
                path: '/home-admin/device/device-borrow',
                name: "转借记录",
                component: () => import("@/components/admin/device/DeviceBorrow"),
                meta: [
                    { name: '设备管理' },
                    { name: '转借记录', url: '/home-admin/device/device-borrow' },
                ]
            },
            {
                path: '/home-admin/journal/login-journal',
                name: "登录日志",
                component: () => import("@/components/admin/journal/LoginJournal"),
                meta: [
                    { name: '登录日志', url: '/home-admin/journal/login-journal' },
                ]
            },
        ]
    }
]

export default routers